import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { Link, NavLink } from "react-router-dom";

import sponsor_1 from "../assets/images/rec_casa_logo_200.png";
import sponsor_2 from "../assets/images/rec_riis_logo.png";
import sponsor_3 from "../assets/images/rec_blume_logo_2.jpeg";
import sponsor_4 from "../assets/images/rec_bkb.png";
import sponsor_5 from "../assets/images/rec_primeo_energie_rgb_72dpi.jpg";

export default function Sponsors() {
  const { t } = useTranslation();

  return (
    <div className="col-12 col-lg-12 px-0">
      <div className="overflow-hidden text-center">
        <h4 className="pt-4 pt-xl-5 my-3 px-3">{t("home_sponsor_title_1")}</h4>
        <div className="container align-items-center justify-content-around">
          <div className="row justify-content-around align-items-center">
            <div className="col custom-col">
              <img
                src={sponsor_4}
                className="sponsor"
                alt="Sponsor logo"
                height="50px"
              />
              <p className="mt-2">
                <a href="https://www.bkb.ch/" target="_blank" rel="noreferrer">
                  Basler&nbsp;Kantonalbank{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    fill="currentColor"
                    className="bi bi-arrow-up-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"
                    />
                  </svg>
                </a>
              </p>
            </div>
            <div className="col custom-col">
              <img
                src={sponsor_5}
                className="sponsor"
                alt="Sponsor logo"
                height="50px"
              />
              <p className="mt-2">
                <a
                  href="https://www.primeo-energie.ch/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Primeo&nbsp;Energie{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    fill="currentColor"
                    className="bi bi-arrow-up-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"
                    />
                  </svg>
                </a>
              </p>
            </div>
          </div>
        </div>
        <h5 className="pt-1 pt-xl-2 my-3 px-3">{t("home_sponsor_title_2")}</h5>
        <div className="container align-items-center justify-content-around">
          <div className="row justify-content-around align-items-center">
            <div className="col">
              <img
                src={sponsor_1}
                className="sponsor"
                alt="Sponsor logo"
                height="50px"
              />
              <p className="mt-2">
                <a
                  href="https://g.co/kgs/Gm1PGsq"
                  target="_blank"
                  rel="noreferrer"
                >
                  Pizzeria&nbsp;Casablanca{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    fill="currentColor"
                    className="bi bi-arrow-up-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"
                    />
                  </svg>
                </a>
              </p>
            </div>
            <div className="col">
              <img
                src={sponsor_2}
                className="sponsor"
                alt="Sponsor logo"
                height="100px"
              />
              <p className="mt-2">
                <a
                  href="https://g.co/kgs/yBiveRo"
                  target="_blank"
                  rel="noreferrer"
                >
                  Riis&nbsp;Wok&nbsp;by&nbsp;Spirig{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    fill="currentColor"
                    className="bi bi-arrow-up-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"
                    />
                  </svg>
                </a>
              </p>
            </div>
            <div className="col">
              <img
                src={sponsor_3}
                className="sponsor rounded-4"
                alt="Sponsor logo"
                height="100px"
              />
              <p className="mt-2">
                <a
                  href="https://g.co/kgs/wCPdXNV"
                  target="_blank"
                  rel="noreferrer"
                >
                  Hotel&nbsp;Restaurant&nbsp;Blume{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    fill="currentColor"
                    className="bi bi-arrow-up-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"
                    />
                  </svg>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
