import { useTranslation } from "react-i18next";
import "../../components/i18n";

interface EachFAQProps {
  questionKey: string;
  answerKey: string;
}

function EachFAQ({ questionKey, answerKey }: EachFAQProps) {
  const { t } = useTranslation();

  return (
    <div className="accordion-item">
      <h2 className="accordion-header">
        <button
          className="accordion-button collapsed faq"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#collapse-${questionKey}`}
          aria-expanded="false"
          aria-controls={`collapse-${questionKey}`}
        >
          {t(questionKey)}
        </button>
      </h2>
      <div
        id={`collapse-${questionKey}`}
        className="accordion-collapse collapse"
      >
        <div className="accordion-body">{t(answerKey)}</div>
      </div>
    </div>
  );
}

export default function FAQ() {
  const { t } = useTranslation();

  return (
    <div className="container p-0 my-0 mt-5 pageClass px-3">
      <h2 className="mb-3"> {t("faq_title")}</h2>

      <h4 className="mb-3"> {t("faq_set_title_1")}</h4>

      <div className="accordion" id="faqAccordion">
        <EachFAQ questionKey="faq_q1" answerKey="faq_a1" />
        <EachFAQ questionKey="faq_q2" answerKey="faq_a2" />
        <EachFAQ questionKey="faq_q3" answerKey="faq_a3" />
        <EachFAQ questionKey="faq_q4" answerKey="faq_a4" />
        <EachFAQ questionKey="faq_q5" answerKey="faq_a5" />
        <EachFAQ questionKey="faq_q6" answerKey="faq_a6" />
        <EachFAQ questionKey="faq_q7" answerKey="faq_a7" />
        <EachFAQ questionKey="faq_q8" answerKey="faq_a8" />
      </div>

      <br />
      <h4 className="mb-3"> {t("faq_set_title_2")}</h4>

      <div className="accordion" id="faqAccordion">
        <EachFAQ questionKey="faq_q9" answerKey="faq_a9" />
        <EachFAQ questionKey="faq_q10" answerKey="faq_a10" />
        <EachFAQ questionKey="faq_q11" answerKey="faq_a11" />
      </div>

      <p className="my-5">
        {t("faq_contact_us_statement")}{" "}
        <a href="mailto:info&#64;outoflines&#46;ch">info[at]outoflines.ch</a>
      </p>
    </div>
  );
}
