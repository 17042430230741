import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import "../../components/i18n";
import axios from "axios";
import { v4 as uuidv4 } from "uuid"; // Import v4 function as uuidv4
import { useNavigate } from "react-router-dom";

export default function TestimonialSubmitPage() {
  const { t } = useTranslation();
  const [userId, setUserId] = useState<string>("");

  useEffect(() => {
    const generatedUserId = uuidv4();
    setUserId(generatedUserId);
  }, []);

  const [email, setEmail] = useState<string>("");

  const [otp, setOtp] = useState<string>("");
  const [otpSent, setOtpSent] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(59); // TIMER
  const [otpVerified, setOtpVerified] = useState<boolean>(false);
  const [fullname, setFullname] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const maxLength = 1400; // Maximum character length allowed
  // const [phone, setPhone] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [otpResent, setOtpResent] = useState(false);
  const [otpResentCount, setOtpResentCount] = useState<number>(0);
  const [otpVerifyBtn, setOtpVerifyBtn] = useState(false);

  const [otpError, setOtpError] = useState(false);
  const [otpErrorText, setOtpErrorText] = useState<string>("");

  const [errors, setErrors] = useState<Record<string, string>>({});
  const [fileError, setFileError] = useState<string | null>(null);

  const [submissionError, setSubmissionError] = useState(false);
  const [submissionErrorText, setSubmissionErrorText] = useState<string>("");
  const [sendOtpError, setSendOtpError] = useState(false);
  const [sendOtpErrorText, setSendOtpErrorText] = useState<string>("");

  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let interval: ReturnType<typeof setInterval> | undefined;

    if (otpSent && timer > 0 && otpResentCount < 3) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0 && interval) {
      clearInterval(interval);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [otpSent, timer, otpResentCount]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    if (value.length <= maxLength) {
      setMessage(value);
    }
    setErrors((prevErrors) => ({ ...prevErrors, message: "" }));

    // console.log(value, message); // need clarity
  };

  // useEffect(() => {
  //   console.log("selectedFile:", selectedFile);
  // }, [selectedFile]);

  const showFile = () => {
    console.log("selectedFile:", selectedFile);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files && e.target.files[0];
      if (file) {
        const maxSize = 5 * 1024 * 1024; // 5 MB
        const allowedTypes = ["image/jpeg", "image/png"];
        const newErrors = { ...errors }; // Copy current errors

        // console.log(file.size / 1024);
        if (file.size > maxSize) {
          newErrors.file = "File must be smaller than 5MB.";
          // setSelectedFile(null);
        } else if (!allowedTypes.includes(file.type)) {
          newErrors.file = "Only JPEG and PNG allowed.";
          // setSelectedFile(null);
        } else {
          delete newErrors.file;
        }

        setSelectedFile(file);

        setErrors(newErrors);
      }
    } else {
      const newErrors = { ...errors }; // Copy current errors

      delete newErrors.file;
      setErrors(newErrors);
      setSelectedFile(null);

      let dataTransfer = new DataTransfer();

      // Or you can manipulate file list here
      // let file = new File(['content'], 'filename.txt')
      // dataTransfer.items.add(file)

      if (fileInputRef.current) {
        fileInputRef.current.files = dataTransfer.files;
      }
    }
  };

  const handleSendOtp = async () => {
    // console.log(`Clicked OTP`, userId);

    setOtpError(false);

    const newErrors = { ...errors }; // Copy current errors
    if (!email) {
      newErrors.email = "Email is required";
    } else if (!isValidEmail(email)) {
      newErrors.email = "Invalid email format";
    } else {
      // Clear the email error if it exists
      delete newErrors.email;
    }

    // Update errors state
    setErrors(newErrors);

    // console.log("len ", Object.keys(newErrors).length);

    if (isValidEmail(email) && otpResentCount <= 2) {
      // console.log(`Sending OTP to ${email}`);

      const otpData = {
        guid: userId,
        email: email,
      };

      try {
        const response = await axios.post(
          // "http://127.0.0.1:3011/send_otp",
          "https://api.outoflines.ch/send_otp",
          otpData
        );

        setOtpResentCount((prevCount) => prevCount + 1);

        // console.log(`Sent OTP to ${email}. Attempt ${otpResentCount}`);
        setSendOtpError(false);

        setOtpSent(true);

        setTimer(59); // TIMER

        // Process response data as needed

        // Process response data as needed
      } catch (error: any) {
        setSendOtpError(true);

        if (error.response) {
          // Server responded with a status code out of the range of 2xx
          if (error.response.data && error.response.data.error) {
            // console.error("Form submission error:", error.response.data.error);
            if (
              error.response.data.error.includes("Data for the email exists.")
            ) {
              setSendOtpErrorText(t("testimonial_exists_email"));
            } else {
              setSendOtpErrorText(t("testimonial_OTP_fail"));
            }
          } else {
            // console.error("Unexpected server error");
            setSendOtpErrorText(t("testimonial_OTP_fail"));
          }
        } else if (error.request) {
          // The request was made but no response was received
          // console.error(
          //   "No response received from server. The server might be offline."
          // );
          setSendOtpErrorText(t("testimonial_OTP_fail"));
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.error("Error in setting up the request:", error.message);
          setSendOtpErrorText(t("testimonial_OTP_fail"));
        }
      } finally {
        // Any cleanup logic can go here
      }
    }
  };

  const handleVerifyOtp = async () => {
    setOtpVerifyBtn(true);

    try {
      const response = await axios.post(
        // "http://127.0.0.1:3011/verify_otp",
        "https://api.outoflines.ch/verify_otp",
        {
          otp: otp,
          guid: userId,
        }
      );

      // Handle response here

      setOtpVerified(true);

      // Process response data as needed

      // Process response data as needed
    } catch (error: any) {
      // console.error("Form submission error:", error.response.data);

      setOtpError(true);

      if (error.response.data && error.response.data.error) {
        setOtpErrorText(t("testimonial_OTP_fail"));
      } else {
        setOtpErrorText(t("testimonial_failed_sending_message"));
      }
    } finally {
      setOtpVerifyBtn(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newErrors = { ...errors };

    if (!fullname) {
      newErrors.fullname = "Full Name is required";
    } else if (!isValidFullName(fullname)) {
      newErrors.fullname = "Enter valid name";
    } else {
      delete newErrors.fullname;
    }

    // if (!phone) {
    //   newErrors.phone = "Phone Number is required";
    // } else if (!isValidPhone(phone)) {
    //   newErrors.phone = "Enter valid phone number";
    // } else {
    //   delete newErrors.phone;
    // }

    const sanitizedMessage = sanitizeMessage(message);

    if (!sanitizedMessage) {
      newErrors.message = "Message is required";
    } else {
      delete newErrors.message;
    }

    // Check if OTP is verified
    if (!otpVerified) {
      newErrors.otp = "Please verify OTP";
    } else {
      delete newErrors.otp;
    }

    setErrors(newErrors);

    // console.log("Error count: ", Object.keys(newErrors).length);

    const sanitizedFullName = sanitizeMessage(fullname);

    if (Object.keys(newErrors).length === 0) {
      // console.log("Form submitting.");
      // console.log(selectedFile);

      // Assuming formData is an object containing form data
      // const formData = {
      //   guid: userId,
      //   fullname:sanitizedFullName,
      //   email:email,
      //   // phone:phone,
      //   message:sanitizedMessage,
      //   image:selectedFile
      // };

      const formData = new FormData();
      formData.append("guid", userId);
      formData.append("fullname", sanitizedFullName);
      formData.append("email", email);
      formData.append("message", sanitizedMessage);
      if (selectedFile) {
        formData.append("image", selectedFile); // Add image file
      } else {
        formData.append("image", ""); // Add image file
      }

      // Send a request to check if the GUID is verified
      try {
        const response = await axios.post(
          // "http://127.0.0.1:3011/submit_testimonial",
          "https://api.outoflines.ch/submit_testimonial",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        // console.log("Form data added to the database:", response.data);

        setEmail("");
        setFullname("");
        setMessage("");
        setSelectedFile(null);

        setOtpVerified(false);
        setErrors({});
        setOtpResent(false);

        // const newGuid = uuidv4();
        // setUserId(newGuid);

        setSuccess(true);

        // Redirect to success page after successful form submission
        navigate("/testimonial"); // Redirect to '/success' route

        // setPhone("");
      } catch (error: any) {
        // console.error("Form submission error:", error.response.data);

        setSubmissionError(true);
        if (error.response && error.response.data && error.response.data.body) {
          const errorMsg = error.response.data.body;

          // console.error("Form submission error:", error.response.data);

          // Map the server error messages to translation keys
          let translationKey;
          if (errorMsg.includes("Failed to verify GUID")) {
            translationKey = "testimonial_guid_fail";
          } else if (errorMsg.includes("Server")) {
            translationKey = "testimonial_server_valid_fail";
          } else {
            translationKey = "testimonial_unexpected_error";
          }

          setSubmissionErrorText(t(translationKey));
        } else if (error.request) {
          setSubmissionErrorText(t("testimonial_no_response"));
        } else {
          setSubmissionErrorText(t("testimonial_failed"));
        }

        setSuccess(false); // Ensure success state is false on error
      } finally {
        // This block will always execute, regardless of errors
        // console.log("Form submission completed.");
      }
    } else {
      // console.error(
      //   "Form submission failed due to client-side validation errors."
      // );
      setSuccess(false); // Ensure success state is false on error
    }
  };

  const isValidEmail = (email: string) => {
    const emailRegex =
      /^[a-zA-Z0-9](?:[a-zA-Z0-9.]*[a-zA-Z0-9])?@[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)+$/;
    return emailRegex.test(email);
  };

  // const isValidPhone = (phone: string) => {
  //   const phoneRegex = /^\+?\s*\d{9,11}\s*\d?$/;

  //   const sanitizedPhone = phone.replace(/\s/g, "");

  //   return phoneRegex.test(sanitizedPhone);
  // };

  const sanitizeMessage = (input: string) => {
    // Define the list of disallowed characters or strings
    const disallowed = ["<", ">", "&", "/", "\\", "javascript:"];

    // Replace disallowed characters or strings with an empty string
    return input.replace(new RegExp(disallowed.join("|"), "gi"), "");
  };

  const isValidFullName = (fullname: string) => {
    const fullNameRegex = /^[a-zA-Z'-]+(?:\s+[a-zA-Z'-]+)*$/;
    return fullNameRegex.test(fullname);
  };

  return (
    <div className="container p-0 my-0 mt-5 pageClass px-3">
      <div className="row justify-content-lg-center mx-0">
        <div className="col-12 col-lg-12 px-0 mb-5">
          <div className="bg-white rounded-0 overflow-hidden container">
            <form onSubmit={handleSubmit} noValidate>
              <div className="row gy-2">
                <div className="col-12">
                  <h1>{t("testimonial_title")}</h1>
                </div>
                <div className="col-12">
                  <label htmlFor="fullname" className="form-label">
                    {t("testimonial_fullname")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    value={fullname}
                    className={`rounded-0 form-control ${
                      errors.fullname ? "border-validation1" : "border-black"
                    }`}
                    onChange={(e) => {
                      setFullname(e.target.value);
                      // Clear error for the fullname field
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        fullname: "",
                      }));
                    }}
                    required
                  />
                </div>
                {errors.fullname && (
                  <div className="text-danger error-message">
                    {errors.fullname}
                  </div>
                )}
                <div className="col-12 col-md-12">
                  <div>
                    <label htmlFor="formFileSm" className="form-label">
                      {t("testimonial_image_upload")}
                    </label>
                    <input
                      className={`rounded-0 form-control form-control-md ${
                        errors.file ? "border-validation1" : "border-black"
                      }`}
                      id="formFileSm"
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      onChange={handleFileChange}
                      ref={fileInputRef}
                    />

                    {/* <button
                      type="button"
                      className="btn btn-custom-1 rounded-5"
                      onClick={showFile}
                    >
                      Show file
                    </button> */}
                  </div>
                  {errors.file && (
                    <div className="text-danger error-message mt-2">
                      {errors.file}
                    </div>
                  )}
                </div>
                {/* <div className="col-12 col-md-6">
                  <label htmlFor="phone" className="form-label">
                    {t("testimonial_phone")}{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <div
                    className={`input-group ${
                      errors.phone ? "border-validation1" : "border-black"
                    }`}
                  >
                    <input
                      type="tel"
                      className={`rounded-0 form-control ${
                        errors.phone ? "border-black" : "border-black"
                      }`}
                      id="phone"
                      name="phone"
                      value={phone}
                      onChange={(e) => {
                        setPhone(e.target.value);
                        // Clear error for the fullname field
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          phone: "",
                        }));
                      }}
                      required
                    />
                  </div>
                  {errors.phone && (
                    <div className="text-danger error-message mt-2">
                      {errors.phone}
                    </div>
                  )}
                </div> */}
                <div className="col-12 message-container">
                  <label htmlFor="message" className="form-label ">
                    {t("testimonial_message")}{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <textarea
                    className={`rounded-0 form-control ${
                      errors.message ? "border-validation1" : "border-black"
                    }`}
                    id="message"
                    name="message"
                    value={message}
                    onChange={handleChange}
                    rows={5}
                    required
                    maxLength={1400}
                  ></textarea>
                  <p
                    className={
                      message.length > 1300
                        ? "char-length high-length"
                        : "char-length"
                    }
                  >
                    &nbsp;{message.length}/1400&nbsp;
                  </p>
                </div>
                {errors.message && (
                  <div className="text-danger error-message">
                    {errors.message}
                  </div>
                )}

                <div className="row justify-content-lg-center mx-0 mt-4">
                  <div className="col-12 col-lg-12 px-0 mb-2 px-lg-5 py-lg-4 px-md-4 py-md-3 px-3 py-2 border rounded-0 shadow-sm overflow-hidden otpClass justify-content-center">
                    <div className="row justify-content-start align-items-end">
                      <div className="col-lg-6 col-md-8 col-sm-8">
                        <label htmlFor="email" className="form-label">
                          {t("testimonial_email")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div
                          className={`input-group  ${
                            errors.email ? "border-validation1" : "border-black"
                          }`}
                        >
                          <input
                            id="email"
                            type="email"
                            className={`rounded-0 form-control ${
                              errors.email ? "border-black" : "border-black"
                            }`}
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                              // Clear error for the fullname field
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                email: "",
                              }));
                            }}
                            disabled={
                              (otpSent && timer !== 0 && !otpResent) ||
                              (otpSent && timer !== 0 && otpResent) ||
                              otpVerified
                            }
                          />
                        </div>
                      </div>

                      {errors.email && (
                        <div className="text-danger error-message mt-2">
                          {errors.email}
                        </div>
                      )}

                      {/* OTP Button */}
                      <div className="col-12 mt-3">
                        {!otpSent && !otpVerified && (
                          <button
                            type="button"
                            className="btn btn-custom-1 rounded-5"
                            onClick={handleSendOtp}
                          >
                            {t("testimonial_send_otp")}
                          </button>
                        )}

                        {otpSent &&
                          timer !== 0 &&
                          !otpResent &&
                          !otpVerified && (
                            <button
                              type="button"
                              className="btn btn-custom-1 rounded-5"
                              disabled
                            >
                              {t("testimonial_send_otp")}{" "}
                            </button>
                          )}

                        {((otpSent &&
                          timer !== 0 &&
                          otpResent &&
                          !otpVerified) ||
                          otpResentCount === 3) && (
                          <button
                            type="button"
                            className="btn btn-custom-1 rounded-5"
                            disabled
                          >
                            {t("testimonial_resend_otp")}{" "}
                          </button>
                        )}

                        {sendOtpError && (
                          <div className="col-12 my-4">
                            <span className="text-danger">
                              {" "}
                              {sendOtpErrorText}
                            </span>
                          </div>
                        )}

                        {otpSent && timer === 0 && !otpVerified && (
                          <button
                            type="button"
                            className="btn btn-custom-1 rounded-5"
                            onClick={() => {
                              handleSendOtp();
                              setOtpResent(true);
                            }}
                          >
                            {t("testimonial_resend_otp")}{" "}
                          </button>
                        )}
                        {!otpVerified && otpResentCount < 3 && (
                          <button className="btn timerBtn" disabled={true}>
                            {otpSent && timer !== 0 && `${timer}s`}
                          </button>
                        )}

                        {!otpVerified && otpResentCount >= 3 && (
                          <p>Maximum attempts reached.</p>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-8 col-sm-10 mt-3">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="d-flex justify-content-start">
                            {!otpVerified && (
                              <div className="d-flex justify-content-start">
                                {otpSent && (
                                  <>
                                    <input
                                      type="text"
                                      className="rounded-0 form-control otp-field "
                                      value={otp}
                                      onChange={(e) => setOtp(e.target.value)}
                                      maxLength={6}
                                      placeholder="OTP"
                                    />

                                    <button
                                      className="btn btn-custom-1 rounded-0 verifybtn-custom-1"
                                      onClick={() => {
                                        handleVerifyOtp();
                                        setOtpVerifyBtn(true);
                                      }}
                                      disabled={otpVerifyBtn}
                                    >
                                      {!otpVerified && otpVerifyBtn
                                        ? t("testimonial_otp_processing")
                                        : t("tesimonial_verify_otp")}
                                    </button>
                                  </>
                                )}
                              </div>
                            )}

                            {otpVerified && (
                              <div className="d-flex justify-content-start">
                                <>
                                  <p className="otp-verified-confirmation">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      className="check-icon bi bi-check-circle"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                      <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                    </svg>
                                    &nbsp;&nbsp;
                                    {t("testimonial_email_verified")}
                                  </p>
                                </>
                              </div>
                            )}
                          </div>
                        </div>

                        {!otpVerified && otpError && (
                          <div className="col-md-12 pt-2">
                            <span className="text-danger"> {otpErrorText}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <p className="text-secondary">{t("terms_statement")}</p>

                  {submissionError && (
                    <div className="col-12 my-4">
                      <span className="text-danger">
                        {" "}
                        {submissionErrorText}
                      </span>
                    </div>
                  )}
                  <div className="d-grid">
                    {otpVerified && (
                      <button
                        className="btn btn-md rounded-5 btn-custom-1"
                        type="submit"
                      >
                        {t("testimonial_submit")}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
