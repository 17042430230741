import React, { useState } from "react";

export default function ScrollToTopButton() {
  const [showButton, setShowButton] = useState(false);

  // Add scroll event listener
  window.addEventListener("scroll", () => {
    if (window.pageYOffset > 20) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  });

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <button
      id="goToTopBtn"
      onClick={scrollToTop}
      style={{ display: showButton ? "block" : "none" }}
    ></button>
  );
}
