import { useTranslation } from "react-i18next";
import "../../components/i18n";

export default function PrivacyPage() {
  const { t } = useTranslation();

  return (
    <div className="container p-0 my-0 mt-5 pageClass px-3">
      <h2 className="mb-3"> {t("privacy_title")}</h2>
      <div className="mb-5">
        <p>{t("privacy_policy_content")}</p>

        <h4>{t("third_parties_title")}</h4>
        <p>{t("third_parties_content")}</p>

        <h4>{t("information_collection_title")}</h4>
        <p>{t("information_collection_content")}</p>
        <p>{t("booking_information")}</p>
        <ul>
          <li>{t("name")}</li>
          <li>{t("dob")}</li>
          <li>{t("gender")}</li>
          <li>{t("email")}</li>
          <li>{t("ip_address")}</li>
          <li>{t("phone")}</li>
        </ul>
        <p>{t("appointment_details")}</p>
        <ul>
          <li>{t("appointment_date_time")}</li>
          <li>{t("appointment_type")}</li>
        </ul>
        <p>{t("payment_info")}</p>
        <p>{t("privacy_contact_form")}</p>
        <p>{t("privacy_testimonial_form")}</p>

        <h4>{t("data_use_title")}</h4>
        <p>{t("data_use_content")}</p>

        <h4>{t("data_security_title")}</h4>
        <p>{t("data_security_content")}</p>

        <h4>{t("data_subject_rights_title")}</h4>
        <p>{t("data_subject_rights_content")}</p>
        <ul>
          <li>{t("right_confirmation")}</li>
          <li>{t("right_access")}</li>
          <li>{t("right_rectification")}</li>
          <li>{t("right_erasure")}</li>
          <li>{t("right_restriction")}</li>
          <li>{t("right_portability")}</li>
          <li>{t("right_object")}</li>
          <li>{t("right_withdraw")}</li>
        </ul>
        <p>{t("data_requests")}</p>

        <h4>{t("contact_info_title")}</h4>
        <p>{t("contact_info_content")}</p>

        <h4>{t("policy_changes_title")}</h4>
        <p>{t("policy_changes_content")}</p>
      </div>
    </div>
  );
}
