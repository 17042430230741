import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../../components/i18n";
import axios from "axios";

export default function ContactForm() {
  const { t } = useTranslation();

  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [email, setEmail] = useState<string>("");
  const [fullname, setFullname] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const maxLength = 300; // Maximum character length allowed
  const [phone, setPhone] = useState<string>("");
  const [isChecked, setIsChecked] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [messageErrorText, setMessageErrorText] = useState<string>("");

  const svgStyle = {
    strokeDasharray: "480px, 480px",
    strokeDashoffset: "960px",
  };

  const [errors, setErrors] = useState<Record<string, string>>({});

  const handleInputSelect1: React.FocusEventHandler<HTMLInputElement> = (
    event
  ) => {
    setSubmitted(false);
    // setMessageError(false);
  };

  const handleInputSelect2: React.FocusEventHandler<HTMLTextAreaElement> = (
    event
  ) => {
    setSubmitted(false);
    // setMessageError(false);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    if (value.length <= maxLength) {
      setMessage(value);
    }
    setErrors((prevErrors) => ({ ...prevErrors, message: "" }));

    // console.log(value, message); // need clarity
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setMessageError(false);
    const newErrors = { ...errors };

    if (!fullname) {
      newErrors.fullname = t("contact_validation_full_name_1");
    } else if (!isValidFullName(fullname)) {
      newErrors.fullname = t("contact_validation_full_name_2");
    } else {
      delete newErrors.fullname;
    }

    if (!phone) {
      delete newErrors.phone;
    } else if (!isValidPhone(phone)) {
      newErrors.phone = t("contact_validation_phone");
    } else {
      delete newErrors.phone;
    }

    const sanitizedMessage = sanitizeMessage(message);
    // console.log(sanitizedMessage);

    if (!sanitizedMessage) {
      newErrors.message = t("contact_validation_message_1");
    } else if (sanitizedMessage.length < 10) {
      newErrors.message = t("contact_validation_message_2");
    } else {
      delete newErrors.message;
    }

    if (!email) {
      newErrors.email = t("contact_validation_email_1");
    } else if (!isValidEmail(email)) {
      newErrors.email = t("contact_validation_email_2");
    } else {
      delete newErrors.email;
    }

    if (!isChecked) {
      newErrors.agreement = t("contact_validation_terms_check");
    } else {
      // Clear the email error if it exists
      delete newErrors.agreement;
    }

    setErrors(newErrors);

    const sanitizedFullName = sanitizeMessage(fullname);

    if (Object.keys(newErrors).length === 0) {
      setSubmitting(true);

      const formData = {
        fullname: sanitizedFullName,
        email: email,
        phone: phone,
        message: sanitizedMessage,
      };

      try {
        const response = await axios.post(
          // "http://127.0.0.1:3011/submit_contact_form",
          "https://api.outoflines.ch/submit_contact_form",

          formData
        );

        // Handle response here
        // console.log("Form submitted successfully!");

        setSubmitted(true);

        setEmail("");
        setFullname("");
        setMessage("");
        setPhone("");
        setIsChecked(false);

        // Process response data as needed

        // Process response data as needed
      } catch (error: any) {
        setMessageError(true);
        if (error.response && error.response.data && error.response.data.body) {
          const errorMsg = error.response.data.body;

          // console.error("Form submission error:", error.response.data);

          // Map the server error messages to translation keys
          let translationKey;
          if (errorMsg.includes("Frequency")) {
            translationKey = "contact_frequency_error";
          } else if (errorMsg.includes("Server")) {
            translationKey = "contact_server_validation_error";
          } else {
            translationKey = "contact_unexpected_error";
          }

          setMessageErrorText(t(translationKey));
        } else if (error.request) {
          setMessageErrorText(t("contact_no_response"));
        } else {
          setMessageErrorText(t("contact_failed"));
        }

        setEmail("");
        setFullname("");
        setMessage("");
        setPhone("");
        setIsChecked(false);
      } finally {
        setSubmitting(false);
      }
    }
  };

  const isValidEmail = (email: string) => {
    const emailRegex =
      /^[a-zA-Z0-9](?:[a-zA-Z0-9.+]*[a-zA-Z0-9])?@[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)+$/;
    return emailRegex.test(email);
  };

  const isValidPhone = (phone: string) => {
    const phoneRegex = /^\+?\s*\d{9,11}\s*\d?$/;

    const sanitizedPhone = phone.replace(/\s/g, "");

    return phoneRegex.test(sanitizedPhone);
  };

  const sanitizeMessage = (input: string) => {
    // Define the list of disallowed characters or strings
    const disallowed = ["<", ">", "&", "/", "\\", "javascript:"];

    // Replace disallowed characters or strings with an empty string
    return input.replace(new RegExp(disallowed.join("|"), "gi"), "");
  };

  const isValidFullName = (fullname: string) => {
    const fullNameRegex = /^[a-zA-Z'-]+(?:\s+[a-zA-Z'-]+)*$/;
    return fullNameRegex.test(fullname);
  };

  return (
    <div className="col-lg-6 col-md-6 col-xs-12 px-0 mb-5">
      <div className="bg-white overflow-hidden container ps-1 pe-lg-3 pe-md-3 pe-1">
        {/* <div className="row gy-2 gy-xl-2 px-0">
          <div className="col-12">
            <p className="text-secondary">{t("contact_statement")}</p>

            <p className="">
              {t("contact_email")}{" "}
              <a href="mailto:info&#64;outoflines&#46;ch">
                info[at]outoflines.ch
              </a>
            </p>
          </div>
        </div> */}

        <form onSubmit={handleSubmit} id="" noValidate>
          <div className="row gy-2 gy-xl-2 px-0">
            <div className="col-12">
              <p className="text-secondary">{t("contact_statement")}</p>

              <p className="">
                {t("contact_email")}{" "}
                <a href="mailto:info&#64;outoflines&#46;ch">
                  info[at]outoflines.ch
                </a>
              </p>
            </div>

            <div className="col-12">
              <label htmlFor="fullname" className="form-label">
                {t("contact_fullname")} <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                value={fullname}
                className={`rounded-0 form-control ${
                  errors.fullname ? "border-validation2" : "border-black"
                }`}
                onChange={(e) => {
                  setFullname(e.target.value);
                  // Clear error for the fullname field
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    fullname: "",
                  }));
                }}
                disabled={submitting}
                required
                onFocus={handleInputSelect1}
              />
            </div>
            {errors.fullname && (
              <div className="text-danger">{errors.fullname}</div>
            )}
            <div className="col-12 col-md-12">
              <label htmlFor="email" className="form-label">
                {t("contact_email")} <span className="text-danger">*</span>
              </label>
              <div
                className={`input-group ${
                  errors.email ? "border-validation2" : "border-black"
                }`}
              >
                <span
                  className={`input-group-text rounded-0 ${
                    errors.email ? "border-black" : "border-black"
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-envelope"
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                  </svg>
                </span>
                <input
                  id="email"
                  type="email"
                  className={`rounded-0 form-control ${
                    errors.email ? "border-black" : "border-black"
                  }`}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    // Clear error for the fullname field
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      email: "",
                    }));
                  }}
                  disabled={submitting}
                  onFocus={handleInputSelect1}
                />
              </div>
              {errors.email && (
                <div className="text-danger mt-2">{errors.email}</div>
              )}
            </div>

            <div className="col-12 col-md-12">
              <label htmlFor="phone" className="form-label">
                {t("contact_phone")}
              </label>
              <div
                className={`input-group ${
                  errors.phone ? "border-validation2" : "border-black"
                }`}
              >
                <span
                  className={`input-group-text rounded-0 ${
                    errors.phone ? "border-black" : "border-black"
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-telephone"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                  </svg>
                </span>
                <input
                  type="tel"
                  className={`rounded-0 form-control ${
                    errors.phone ? "border-black" : "border-black"
                  }`}
                  id="phone"
                  name="phone"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                    // Clear error for the fullname field
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      phone: "",
                    }));
                  }}
                  disabled={submitting}
                  required
                  onFocus={handleInputSelect1}
                />
              </div>
              {errors.phone && (
                <div className="text-danger mt-2">{errors.phone}</div>
              )}
            </div>
            <div className="col-12 message-container">
              <label htmlFor="message" className="form-label ">
                {t("contact_message")} <span className="text-danger">*</span>
              </label>
              <textarea
                className={`rounded-0 form-control ${
                  errors.message ? "border-validation2" : "border-black"
                }`}
                id="message"
                name="message"
                value={message}
                onChange={handleChange}
                rows={5}
                required
                maxLength={300}
                disabled={submitting}
                onFocus={handleInputSelect2}
              ></textarea>
              <p
                className={
                  message.length > 200
                    ? "char-length high-length"
                    : "char-length"
                }
              >
                &nbsp;{message.length}/300&nbsp;
              </p>
            </div>
            {errors.message && (
              <div className="text-danger">{errors.message}</div>
            )}

            <div className="col-12">
              <div className="mb-3 form-check">
                <input
                  type="checkbox"
                  checked={isChecked}
                  className="form-check-input"
                  onChange={(e) => {
                    setIsChecked(e.target.checked);
                    // Clear error for the fullname field
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      agreement: "",
                    }));
                  }}
                  id="agree"
                  required
                  disabled={submitting}
                  onFocus={handleInputSelect1}
                />
                <label className="form-check-label" htmlFor="agree">
                  {t("contact_terms_check")}{" "}
                  <span className="text-danger">*</span>
                </label>
                {errors.agreement && (
                  <div className="text-danger">{errors.agreement}</div>
                )}
              </div>

              {messageError && (
                <div className="col-12 my-4">
                  <span className="text-danger"> {messageErrorText}</span>
                </div>
              )}

              <div className="d-grid">
                <button
                  className={
                    submitted
                      ? "btn btn-lg rounded-5 btn-custom-1 submitted"
                      : submitting
                      ? "btn btn-lg rounded-5 btn-custom-1 submitting-animation "
                      : "btn btn-lg rounded-5 btn-custom-1"
                  }
                  // className="btn btn-lg otpBtn rounded-5"
                  type="submit"
                  disabled={submitting || submitted}
                >
                  {submitting ? (
                    t("contact_submitting")
                  ) : submitted ? (
                    <>
                      <svg
                        className="tick-svg"
                        width="28"
                        height="28"
                        viewBox="0 0 64 64"
                      >
                        <polyline
                          points="5 30.3 25.3 50.5 59 16.8"
                          fill="none"
                          stroke="#68E534"
                          strokeWidth="9"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="tick"
                        />
                      </svg>
                    </>
                  ) : (
                    t("contact_submit")
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
