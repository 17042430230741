import { useTranslation } from "react-i18next";
import "../../components/i18n";
import Sponsors from "../../components/Sponsors";

export default function PrivacyPage() {
  const { t } = useTranslation();

  return (
    <div className="container p-0 my-0 my-5 pageClass px-3">
      <h2 className="mb-3"> {t("sponsor_title_1")}</h2>
      <p className="mt-2">{t("sponsor_content_1")}</p>{" "}
      <h3 className="mb-3"> {t("sponsor_title_2")}</h3>
      <p className="mt-2">
        {t("sponsor_content_2")} {t("sponsor_content_3")}{" "}
        {t("sponsor_content_4")}:{" "}
        <a href="mailto:sponsoring&#64;outoflines&#46;ch">
          sponsoring[at]outoflines.ch
        </a>
      </p>{" "}
      <div className="row justify-content-lg-center">
        <div className="d-flex flex-column align-items-center">
          <Sponsors />
        </div>
      </div>
    </div>
  );
}
