import portrait_a from "../../assets/images/portrait_a.png";
import portrait_b from "../../assets/images/portrait_b.png";
import portrait_c from "../../assets/images/portrait_c.png";
import portrait_d from "../../assets/images/portrait_d.png";
import portrait_e from "../../assets/images/portrait_e.png";

import { useTranslation } from "react-i18next";
import "../../components/i18n";

interface AboutComponentProps {
  subtitleKey: string;
  nameKey: string;
  paragraphs: string[];
  portrait: string; // Add portrait prop
  img_alt: string;
}

function AboutComponent({
  subtitleKey,
  nameKey,
  paragraphs,
  portrait,
  img_alt,
}: AboutComponentProps) {
  const { t } = useTranslation();

  return (
    <div className="row gx-4 align-items-center justify-content-between">
      <div className="mt-3">
        <span className="text-muted"> {t(subtitleKey)}</span>
        <h2 className=" fw-bold">{t(nameKey)}</h2>
        <img
          src={portrait}
          className="float-lg-center float-md-end float-sm-end border border-black ms-2 about-image"
          alt={img_alt}
        />
        {paragraphs.map((paragraph, index) => (
          <p key={index} className="about-text text-wrap">
            {t(paragraph)}
          </p>
        ))}
      </div>
    </div>
  );
}

export default function About() {
  const { t } = useTranslation();

  return (
    <div className="container p-0 my-0 mt-3 pageClass px-3 mb-5">
      <div className="row gx-4 align-items-center justify-content-between">
        <div className="mt-3">
          <span className="text-muted"> {t("about_a_subtitle")}</span>
          <h2 className=" fw-bold">{t("about_a_name")}</h2>
          <img
            src={portrait_a}
            className="float-lg-center float-md-end float-sm-end border border-black ms-2 about-image"
            alt={t("about_img_alt_a")}
          />

          <p className="about-text text-wrap">{t("about_a_about1")}</p>
          <p className="about-text text-wrap">{t("about_a_about2")}</p>
          <p className="about-text text-wrap">{t("about_a_about3")}</p>
        </div>
      </div>

      <div className="row gx-4 align-items-center justify-content-between">
        <div className="mt-3">
          <span className="text-muted"> {t("about_b_subtitle")}</span>
          <h2 className=" fw-bold">{t("about_b_name")}</h2>
          <img
            src={portrait_b}
            className="float-lg-center float-md-end float-sm-end border border-black ms-2 about-image"
            alt={t("about_img_alt_b")}
          />

          <p className="about-text text-wrap">{t("about_b_about1")}</p>
        </div>
      </div>

      <div className="row gx-4 align-items-center justify-content-between">
        <div className="mt-3">
          <span className="text-muted"> {t("about_c_subtitle")}</span>
          <h2 className=" fw-bold">{t("about_c_name")}</h2>
          <img
            src={portrait_c}
            className="float-lg-center float-md-end float-sm-end border border-black ms-2 about-image"
            alt={t("about_img_alt_a")}
          />
          <p className="about-text text-wrap">{t("about_c_about1")}</p>

          <p className="about-text text-wrap">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              color="#C65C39"
              className="bi bi-quote"
              viewBox="0 0 16 16"
            >
              <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388q0-.527.062-1.054.093-.558.31-.992t.559-.683q.34-.279.868-.279V3q-.868 0-1.52.372a3.3 3.3 0 0 0-1.085.992 4.9 4.9 0 0 0-.62 1.458A7.7 7.7 0 0 0 9 7.558V11a1 1 0 0 0 1 1zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612q0-.527.062-1.054.094-.558.31-.992.217-.434.559-.683.34-.279.868-.279V3q-.868 0-1.52.372a3.3 3.3 0 0 0-1.085.992 4.9 4.9 0 0 0-.62 1.458A7.7 7.7 0 0 0 3 7.558V11a1 1 0 0 0 1 1z" />
            </svg>
            {t("about_c_about2")}
          </p>
          <p className="about-text text-wrap">{t("about_c_about3")}</p>
          <p className="about-text text-wrap">
            {t("about_c_about4")}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              color="#C65C39"
              className="bi bi-quote end-quote"
              viewBox="0 0 16 16"
            >
              <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388q0-.527.062-1.054.093-.558.31-.992t.559-.683q.34-.279.868-.279V3q-.868 0-1.52.372a3.3 3.3 0 0 0-1.085.992 4.9 4.9 0 0 0-.62 1.458A7.7 7.7 0 0 0 9 7.558V11a1 1 0 0 0 1 1zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612q0-.527.062-1.054.094-.558.31-.992.217-.434.559-.683.34-.279.868-.279V3q-.868 0-1.52.372a3.3 3.3 0 0 0-1.085.992 4.9 4.9 0 0 0-.62 1.458A7.7 7.7 0 0 0 3 7.558V11a1 1 0 0 0 1 1z" />
            </svg>
          </p>
        </div>
      </div>

      <div className="row gx-4 align-items-center justify-content-between">
        <div className="mt-3">
          <span className="text-muted"> {t("about_d_subtitle")}</span>
          <h2 className=" fw-bold">{t("about_d_name")}</h2>
          <img
            src={portrait_d}
            className="float-lg-center float-md-end float-sm-end border border-black ms-2 about-image"
            alt={t("about_img_alt_a")}
          />
          <p className="about-text text-wrap">{t("about_d_about1")}</p>
          <p className="about-text text-wrap">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              color="#C65C39"
              className="bi bi-quote"
              viewBox="0 0 16 16"
            >
              <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388q0-.527.062-1.054.093-.558.31-.992t.559-.683q.34-.279.868-.279V3q-.868 0-1.52.372a3.3 3.3 0 0 0-1.085.992 4.9 4.9 0 0 0-.62 1.458A7.7 7.7 0 0 0 9 7.558V11a1 1 0 0 0 1 1zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612q0-.527.062-1.054.094-.558.31-.992.217-.434.559-.683.34-.279.868-.279V3q-.868 0-1.52.372a3.3 3.3 0 0 0-1.085.992 4.9 4.9 0 0 0-.62 1.458A7.7 7.7 0 0 0 3 7.558V11a1 1 0 0 0 1 1z" />
            </svg>
            {t("about_d_about2")}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              color="#C65C39"
              className="bi bi-quote end-quote"
              viewBox="0 0 16 16"
            >
              <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388q0-.527.062-1.054.093-.558.31-.992t.559-.683q.34-.279.868-.279V3q-.868 0-1.52.372a3.3 3.3 0 0 0-1.085.992 4.9 4.9 0 0 0-.62 1.458A7.7 7.7 0 0 0 9 7.558V11a1 1 0 0 0 1 1zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612q0-.527.062-1.054.094-.558.31-.992.217-.434.559-.683.34-.279.868-.279V3q-.868 0-1.52.372a3.3 3.3 0 0 0-1.085.992 4.9 4.9 0 0 0-.62 1.458A7.7 7.7 0 0 0 3 7.558V11a1 1 0 0 0 1 1z" />
            </svg>
          </p>
        </div>
      </div>

      <div className="row gx-4 align-items-center justify-content-between">
        <div className="mt-3">
          <span className="text-muted"> {t("about_e_subtitle")}</span>
          <h2 className=" fw-bold">{t("about_e_name")}</h2>
          <img
            src={portrait_e}
            className="float-lg-center float-md-end float-sm-end border border-black ms-2 about-image"
            alt={t("about_img_alt_a")}
          />
          <p className="about-text text-wrap">{t("about_e_about1")}</p>
          <p className="about-text text-wrap">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              color="#C65C39"
              className="bi bi-quote"
              viewBox="0 0 16 16"
            >
              <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388q0-.527.062-1.054.093-.558.31-.992t.559-.683q.34-.279.868-.279V3q-.868 0-1.52.372a3.3 3.3 0 0 0-1.085.992 4.9 4.9 0 0 0-.62 1.458A7.7 7.7 0 0 0 9 7.558V11a1 1 0 0 0 1 1zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612q0-.527.062-1.054.094-.558.31-.992.217-.434.559-.683.34-.279.868-.279V3q-.868 0-1.52.372a3.3 3.3 0 0 0-1.085.992 4.9 4.9 0 0 0-.62 1.458A7.7 7.7 0 0 0 3 7.558V11a1 1 0 0 0 1 1z" />
            </svg>
            {t("about_e_about2")}
          </p>
          <p className="about-text text-wrap">{t("about_e_about3")}</p>
          <p className="about-text text-wrap">
            {t("about_e_about4")}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              color="#C65C39"
              className="bi bi-quote end-quote"
              viewBox="0 0 16 16"
            >
              <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388q0-.527.062-1.054.093-.558.31-.992t.559-.683q.34-.279.868-.279V3q-.868 0-1.52.372a3.3 3.3 0 0 0-1.085.992 4.9 4.9 0 0 0-.62 1.458A7.7 7.7 0 0 0 9 7.558V11a1 1 0 0 0 1 1zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612q0-.527.062-1.054.094-.558.31-.992.217-.434.559-.683.34-.279.868-.279V3q-.868 0-1.52.372a3.3 3.3 0 0 0-1.085.992 4.9 4.9 0 0 0-.62 1.458A7.7 7.7 0 0 0 3 7.558V11a1 1 0 0 0 1 1z" />
            </svg>
          </p>
        </div>
      </div>
    </div>
  );
}
