import { useTranslation } from "react-i18next";
import "../../components/i18n";

export {};

export default function SitemapPage() {
  const { t } = useTranslation();

  return (
    <div className="container p-0 my-0 mt-5 pageClass px-3">
      <h2 className="mb-3"> {t("sitemap_title")}</h2>

      <p className="my-5">{t("faq_contact_us_statement")}</p>
    </div>
  );
}
