import logo from "../assets/images/logo.png";
import procirque from "../assets/images/procirque-logo.webp";
import facebook from "../assets/images/facebook-24.png";
import instagram from "../assets/images/instagram-24.png";
// import logo from "../assets/images/logo_circle_2.png";
import { useTranslation } from "react-i18next";
import "./i18n";
import { NavLink } from "react-router-dom";

export default function Footer() {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  const handleFooterNavClick = () => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  };

  return (
    <div className="container p-0 my-0 footerClass px-3">
      <footer>
        <div className="container row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4 py-0 my-0 mt-0 mx-0 justify-content-between pt-4">
          <div className="col mb-3 ">
            <h5>{t("footer_quick_links_title")}</h5>
            <ul className="nav flex-column">
              <li className="nav-item mb-2">
                <a
                  href="https://maps.app.goo.gl/gD9iat9LSmAfXg2B7"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("footer_quick_link_map")}
                </a>
              </li>
              <li className="nav-item mb-2">
                <a href="mailto:info&#64;outoflines&#46;ch">
                  {" "}
                  {t("footer_quick_link_email")}
                </a>
              </li>
              <li className="nav-item mb-2">
                <NavLink
                  to="/sponsor"
                  className="p-0"
                  onClick={handleFooterNavClick}
                >
                  {t("footer_quick_link_sponsor")}
                </NavLink>
              </li>
              <li className="nav-item mb-2 ">
                <NavLink
                  to="/privacy"
                  className="p-0"
                  onClick={handleFooterNavClick}
                >
                  {t("footer_privacy_title")}
                </NavLink>
              </li>
              {/* <li className="nav-item mb-2">
                <NavLink
                  to="/testimonial"
                  className="p-0"
                  onClick={handleFooterNavClick}
                >
                  {t("footer_testimonial")}
                </NavLink>
              </li> */}
            </ul>
          </div>

          <div className="col mb-3 ">
            <h5>{t("footer_social")}</h5>

            <div className="d-flex gap-2 align-items-center">
              <a
                href="https://www.facebook.com/profile.php?id=61559560784252"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={facebook} alt="Facebook" width="24" height="24" />
              </a>
              <a
                href="https://instagram.com/flyingtrapeze.ch"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={instagram} alt="Instagram" width="24" height="24" />
              </a>
            </div>
          </div>

          <div className="col mb-3 d-none d-lg-block"></div>

          <div className="col mb-3 ">
            <h5> {t("footer_location_title")}</h5>
            <p className="text-black">
              {t("footer_location_1")}
              <br />
              {t("footer_location_2")}
              <br />
              {t("footer_location_3")}
              <br />
              {t("footer_location_4")}
              <br />
              {t("footer_location_5")}
            </p>
          </div>

          <div className="col mb-3 ">
            <a
              href="https://procirque.ch/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="img-fluid"
                width="120"
                src={procirque}
                alt="Procirque Logo"
              />{" "}
            </a>
          </div>

          <div className="col mb-3 ">
            <p className="text-black">
              {" "}
              {t("footer_copyright")}&nbsp;{currentYear}
            </p>

            <NavLink
              to="/"
              className="d-flex align-items-center mb-3 link-body-emphasis text-decoration-none"
              onClick={handleFooterNavClick}
            >
              <img alt="Logo" width="120" src={logo} />{" "}
            </NavLink>
            <small className="design vrp">
              {t("footer_website_developer")}{" "}
              <a
                href="https://vvkrddy.com"
                target="_blank"
                rel="noopener noreferrer"
                className="vrp1"
              >
                Vivek{" "}
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {" "}
                  <path
                    d="M2.07102 11.3494L0.963068 10.2415L9.2017 1.98864H2.83807L2.85227 0.454545H11.8438V9.46023H10.2955L10.3097 3.09659L2.07102 11.3494Z"
                    fill="currentColor"
                  />{" "}
                </svg>{" "}
              </a>{" "}
            </small>
          </div>
        </div>
      </footer>
    </div>
  );
}
