import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./i18n";

const CookieConsentBanner = () => {
  const [consentGiven, setConsentGiven] = useState(false);

  const handleConsent = () => {
    // Set consent given to true
    setConsentGiven(true);

    // Store the consent information in local storage
    localStorage.setItem("cookieConsent", "true");
  };

  // Check if user has already given consent
  const isConsentGiven =
    localStorage.getItem("cookieConsent") === "true" || consentGiven;

  const handleFooterNavClick = () => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  };

  const { t } = useTranslation();

  // Render the banner only if consent is not given
  if (!isConsentGiven) {
    return (
      <div className="cookie-consent-banner fixed-bottom bg-light border-top p-3">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col">
              <p className="mb-0">
                {t("cookie_consent_1")}{" "}
                <NavLink
                  to="/privacy"
                  className="p-0"
                  onClick={handleFooterNavClick}
                >
                  {t("cookie_consent_2")}.
                </NavLink>
              </p>
            </div>
            <div className="col-auto">
              <button className="cookie-button" onClick={handleConsent}>
                {t("cookie_button")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null; // Don't render anything if consent is given
};

export default CookieConsentBanner;
