import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { Link, NavLink } from "react-router-dom";
// import logo from "../assets/images/logo.png";
import logo from "../assets/images/logo.png";
import LanguageSwitcher from "./LanguageSwitcher";
import "./i18n";
import GoToTop from "./GoToTop";

export default function Header() {
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null); // Explicit type assertion

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    if (isMenuOpen) {
      if (buttonRef.current) {
        buttonRef.current.click();
        setIsMenuOpen(!isMenuOpen);
      }
    }
  };

  // DOUBT does this effect performance
  useEffect(() => {
    const handleScroll = () => {
      // Check if the menu is open
      closeMenu();
    };

    // Attach the scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    // console.log(isModalOpen);
    closeMenu();

    document.body.style.overflow = "hidden";
    // document.body.style.overflow = "scroll";
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = "scroll";
  };

  useEffect(() => {
    // Function to close the modal when Escape key is pressed
    const handleEscapeKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        closeModal();
      }
    };

    // Add event listener for 'keydown' event on the document
    document.addEventListener("keydown", handleEscapeKeyPress);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleEscapeKeyPress);
    };
  }); // Ensure that the effect runs only when closeModal prop changes

  return (
    <div className="container p-0 my-0 headerClass px-3">
      <nav className="navbar navbar-expand-md my-0 py-0 pb-2" id="mainNav">
        <div className="container px-0">
          <div className="col navbar-brand">
            <Link to="/">
              <img alt="Logo" width="80" src={logo} />
            </Link>
          </div>

          <div className="col-auto rounded-0 language-switcher py-1 dark px-xl-0 px-lg-0 px-md-0 px-sm-3  px-3 order-lg-2 order-md-2 mx-0 d-none d-md-block">
            <button
              className={`ls-btn ${
                t("current_language") === "DE" ? "ls-btn-selected" : ""
              }`}
              onClick={() => handleLanguageChange("de")}
            >
              DE
            </button>
            <button
              className={`ls-btn ${
                t("current_language") === "FR" ? "ls-btn-selected" : ""
              }`}
              onClick={() => handleLanguageChange("fr")}
            >
              FR
            </button>
            <button
              className={`ls-btn ${
                t("current_language") === "EN" ? "ls-btn-selected" : ""
              }`}
              onClick={() => handleLanguageChange("en")}
            >
              EN
            </button>
          </div>

          <NavLink
            className="col-auto rounded-0 reservation py-1 dark px-2 order-lg-2 order-md-2 mx-0 d-xl-none d-lg-none d-md-none d-block "
            to="/booking"
            onClick={closeMenu}
          >
            {t("nav_booking")}
          </NavLink>

          <button
            className="col-auto rounded-0 language-switcher py-1 dark px-2 order-lg-2 order-md-2 mx-0  d-xl-none d-lg-none d-md-none d-block "
            onClick={toggleModal}
          >
            {t("current_language")}
          </button>

          <button
            ref={buttonRef}
            className="navbar-toggler pe-0"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={toggleMenu}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight"
          >
            <span className="navbar-toggler-icon"></span>
          </button> */}

          <div
            className="col-auto collapse navbar-collapse justify-content-end mx-lg-3 mx-md-3"
            id="navbarNav"
          >
            <ul className="navbar-nav">
              <li className="nav-item ">
                <NavLink
                  className="nav-link text-end underline mx-1"
                  to="/about"
                  onClick={closeMenu}
                >
                  {t("nav_about")}
                </NavLink>
              </li>

              <li className="nav-item ">
                <NavLink
                  className="nav-link text-end underline mx-1"
                  to="/courses"
                  onClick={closeMenu}
                >
                  {t("nav_courses")}
                </NavLink>
              </li>

              <li className="nav-item ">
                <NavLink
                  className="nav-link text-end underline mx-1"
                  to="/contact"
                  onClick={closeMenu}
                >
                  {t("nav_contact")}
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  className="nav-link text-end underline mx-1"
                  to="/testimonial"
                  onClick={closeMenu}
                >
                  {t("nav_testimonial")}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link text-end underline mx-1"
                  to="/faq"
                  onClick={closeMenu}
                >
                  {t("nav_faq")}
                </NavLink>
              </li>

              <li className="nav-item d-none d-md-block ">
                <NavLink
                  className=" col-auto rounded-0 reservation py-1 dark px-2 order-lg-2 order-md-2 ms-3 d-xl-block d-lg-block d-md-block d-sm-none d-sm-none "
                  to="/booking"
                  onClick={closeMenu}
                >
                  {t("nav_booking")}
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="border-nav-l d-none d-md-block">&nbsp;</div>
          <div className="border-nav-r d-none d-md-block">&nbsp;</div>
        </div>
      </nav>

      <GoToTop />
      {isModalOpen && <LanguageSwitcher closeModal={closeModal} />}
    </div>
  );
}
