import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { Link, NavLink } from "react-router-dom";

import sponsor_1 from "../assets/images/rec_casa_logo_200.png";
import sponsor_2 from "../assets/images/rec_riis_logo.png";
import sponsor_3 from "../assets/images/rec_blume_logo_2.jpeg";
import sponsor_4 from "../assets/images/rec_bkb.png";
import sponsor_5 from "../assets/images/rec_primeo_energie_rgb_72dpi.jpg";

export default function Sponsors() {
  const { t } = useTranslation();

  return (
    <div className="col-12 col-lg-12 px-0">
      <div className="overflow-hidden text-center">
        <h4 className="pt-4 pt-xl-5 my-3 px-3">{t("home_news_title_1")}</h4>
        <div className="container align-items-center justify-content-around">


        <div className="video-container1">

        <iframe width="560" height="315" src="https://www.youtube.com/embed/fMNSLc5dKPs?si=9PO_oZvOAXjUn1sk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>

</div>
        </div>
      </div>
    </div>
  );
}
