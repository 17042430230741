import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";

export default function Booking() {
  const { t } = useTranslation();

  return (
    <div className="container p-0 my-3 mb-5  pageClass px-3">
      <h2 className="mb-3">{t("courses_title")}</h2>

      <p className="text-justify">{t("courses_p1")} </p>
      <p className="text-justify">{t("courses_p2")} </p>

      <h3 className="mb-3">{t("courses_rtitle")}</h3>
      <p className="text-justify">
        <b> {t("courses_r1l")}:</b> {t("courses_r1r")}
      </p>
      <p className="text-justify">
        <b> {t("courses_r2l")}:</b> {t("courses_r2r")}
      </p>
      <p className="text-justify">
        <b> {t("courses_r3l")}:</b> {t("courses_r3r")}
      </p>
      <p className="text-justify">
        <b> {t("courses_r4l")}</b> {t("courses_r4r1")}{" "}
        <b>
          {" "}
          <a href="mailto:info&#64;outoflines&#46;ch">info[at]outoflines.ch</a>
        </b>
        {t("courses_r4r2")}{" "}
      </p>

      <h3 className="mb-3">{t("courses_aititle")}</h3>
      <p className="text-justify">
        <b> {t("courses_ai1l")}:</b> {t("courses_ai1r")}
      </p>
      <p className="text-justify">
        <b> {t("courses_ai2l")}:</b> {t("courses_ai2r")}
      </p>
      <p className="text-justify">
        <b> {t("courses_ai3l")}:</b> {t("courses_ai3r")}
      </p>
      <p className="text-justify">
        <b> {t("courses_ai4l")}</b> {t("courses_ai4r1")}
        <a href="https://www.flying-trapeze.com" target="_blank">
          https://www.flying-trapeze.com
        </a>
        {t("courses_ai4r2")}{" "}
      </p>

      <h3 className="mb-3">{t("courses_cdtitle")}</h3>
      <p className="text-justify">
        <b> {t("courses_cd1h")}</b> {t("courses_cd1p")}
      </p>
      <p className="text-justify">
        <b> {t("courses_cd2h")}</b> {t("courses_cd2p")}
      </p>
      <p className="text-justify">
        <b> {t("courses_cd3h")}</b> {t("courses_cd3p")}
      </p>
      <p className="text-justify">
        <b> {t("courses_cd4h")}</b> {t("courses_cd4p")}
      </p>
    </div>
  );
}

// 425574XYJJ918F9FE963B0
