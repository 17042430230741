import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "../../components/i18n";
import ContactForm from "./ContactForm";
import ContactMap from "./ContactMap";

export default function Contact() {
  const { t } = useTranslation();

  return (
    <div className="container p-0 my-0 mt-5 pageClass px-3">
      <div className="row  mx-0">
        <ContactForm />
        <ContactMap />
      </div>
      {/* <hr />
      <div className="col-12 mt-5 mb-5">
        {t("contact_dev_1")}{" "}
        <a href="mailto:info&#64;outoflines&#46;ch">{t("contact_dev_2")}</a>
      </div> */}
    </div>
  );
}
