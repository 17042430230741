import { useTranslation } from "react-i18next";
import "../../components/i18n";

export default function NotFoundPage() {
  const { t } = useTranslation();
  return (
    <div className="container p-5  my-5 pageClass p-3 text-center">
      <h1>404</h1>
      <p className="text-center">{t("404_statement")}.</p>
    </div>
  );
}
