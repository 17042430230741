import { useTranslation } from "react-i18next";
import "../../components/i18n";
import { NavLink } from "react-router-dom";
import image_1 from "../../assets/images/testimonials/bc2a0bb7-7f5f-4b3d-9158-a7276be53aa0.jpeg";

export default function TestimonialPage() {
  const { t } = useTranslation();

  return (
    <div className="container p-0 my-5 pageClass px-3">
      <div className="card testimonial-prompt border rounded-0 shadow-sm">
        <div className="card-body">
          <h5 className="card-title">{t("submit_testimonial_1")}</h5>
          <p className="card-text">{t("submit_testimonial_2")} </p>

          <NavLink className="btn btn-md rounded-5 btn-custom-1" to="./submit">
            {t("footer_testimonial")}
          </NavLink>
        </div>
      </div>

      <div className="container my-5 px-0 rounded-0">
        <div className="card mb-3 testimonial-card rounded-0">
          <div className="row g-0 d-flex flex-column flex-md-row">
            <div className="col-md-4 d-flex justify-content-center align-items-center">
              <img
                src={image_1}
                className="img-fluid testimonial-image rounded-0 p-3 "
                alt="Person Image"
              />
            </div>
            <div className="col-md-8">
              <div className="card-body">
                <p className="card-text">{t("testimonial_1_p1")}</p>
                <p className="card-text">{t("testimonial_1_p2")}</p>
                <p className="card-text">- {t("testimonial_1_p3")} </p>
                <p className="card-text">
                  <small className="text-muted">
                    {t("testimonial_1_language")}
                  </small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
