import React from "react";
import { useTranslation } from "react-i18next";

interface LanguageSwitcherProps {
  closeModal: () => void; // Define the type of the closeModal prop
}

export default function LanguageSwitcher({
  closeModal,
}: LanguageSwitcherProps) {
  const { i18n } = useTranslation();

  const handleLanguageChange = (language: string) => {
    i18n.changeLanguage(language);
    closeModal();
  };

  return (
    <div>
      <div className="ls-modal">
        {i18n.language !== "de" && (
          <button
            className="stacked-button"
            onClick={() => handleLanguageChange("de")}
          >
            Deutsch
          </button>
        )}
        {i18n.language !== "fr" && (
          <button
            className="stacked-button"
            onClick={() => handleLanguageChange("fr")}
          >
            Français
          </button>
        )}
        {i18n.language !== "en" && (
          <button
            className="stacked-button"
            onClick={() => handleLanguageChange("en")}
          >
            English
          </button>
        )}
      </div>

      <div className="overlay" onClick={closeModal}></div>
    </div>
  );
}
