import { useTranslation } from "react-i18next";
import "../../components/i18n";

export default function Address() {
  const { t } = useTranslation();

  return (
    <div className="col-lg-6 col-md-6 col-xs-12 px-0 mb-5 pe-1 ps-lg-3 ps-md-3 ps-1">
      <iframe
        className="maps-class1"
        src={t("map")}
        allowFullScreen={false}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="title"
      ></iframe>
    </div>
  );
}
